/**
 * Hook which tracks on Google Analytics Data.
 */
export const useGoogleAnalytics = () => {
  const trackingId = process.env.GATSBY_GA_TRACKING_ID;
  const getCid = () => {
    try {
      var trackers = window.ga.getAll()
      var i, len
      for (i = 0, len = trackers.length; i < len; i += 1) {
        if (trackers[i].get("trackingId") === trackingId) {
          return trackers[i].get("clientId")
        }
      }
    } catch (e) {}
    return ""
  }

  return {
    getCid,
    trackingId,
  }
}
