import React, { useRef, useEffect } from 'react'
import { Link } from 'gatsby'
import Arrow from '../../images/link-arrow.svg'
import ArrowRight from '../../images/icons/chevron-right.svg'
import { useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import ClipLoader from 'react-spinners/ClipLoader'
import { useGoogleAnalytics } from "../../hooks/useGoogleAnalytics"

const SEND_SUPPORT_EMAIL = gql`
  mutation sendSupportEmail($input: SendSupportEmailInput!) {
    sendSupportEmail(input: $input) {
      mutationStatus
    }
  }
`;

export default function ContactForm() {

  const [checked, setChecked] = useState(false)
  const [details, setDetails] = useState({
    company: '',
    name: '',
    email: '',
    phone: ''
  })

  const formRef = useRef()
  const [status, setStatus] = useState('')
  const [message, setMessage] = useState('')

  const [sendSupportEmail] = useMutation(SEND_SUPPORT_EMAIL, {
    onCompleted: (res) => {
      console.log(res);
      formRef.current.reset();
      setStatus('resolved')
      setChecked(false)
      setMessage('Ditt mail har skickats!')
    },
    onError: (err) => {
      console.log(err);
      setMessage(err.message)
    }
  })

  const { getCid, trackingId } = useGoogleAnalytics()


  const submit = async (e) => {
    e.preventDefault();
    const clientId = await getCid()
    setStatus('resolving')
    await sendSupportEmail({
      variables: {
        input: {
          name: details.name,
          company: details.company,
          email: details.email,
          phone: details.phone,
          gaCID: clientId,
          gaTID: trackingId,
        },
      },
    })
  }

  return (
    <div className="contact-form">
      <h2>Är ni fler än 20?</h2>
      <p>Fyll då i kontaktuppgifterna nedan och skicka in så tar vi kontakt och förser er med all behövlig information kring detta.</p>
      <form onSubmit={submit} ref={formRef}>
        <div className="form-row">
          <label htmlFor="company">Företagsnamn</label>
          <input onChange={(e) => setDetails({ ...details, company: e.target.value })} required type="text" name="company" id="company" autoComplete="off" />
        </div>
        <div className="form-row">
          <label htmlFor="name">Namn/Efternamn</label>
          <input onChange={(e) => setDetails({ ...details, name: e.target.value })} required type="text" name="name" id="name" autoComplete="off" />
        </div>
        <div className="form-row">
          <label htmlFor="email">E-post</label>
          <input onChange={(e) => setDetails({ ...details, email: e.target.value })} required type="email" name="email" id="email" autoComplete="off" />
        </div>
        <div className="form-row">
          <label htmlFor="phone">Telefonnummer</label>
          <input onChange={(e) => setDetails({ ...details, phone: e.target.value })} required type="tel" name="phone" id="phone" autoComplete="off" />
        </div>
        <div className="form-row">
          <label className="checkbox-container">
            <span>Jag samtycker till att Pausit sparar mina personuppgifter i syfte att fullfölja vår dialog. Personuppgifterna sparas enligt vår <a href="/integritetspolicy.pdf" target="_blank">integritetspolicy</a></span>
            <input required type="checkbox" name="agreement" onChange={(e) => setChecked(e.target.checked)} />
            <span className="checkmark"></span>
          </label>
        </div>
        <button disabled={status === 'resolving' || !checked} type="submit">
          Bli kontaktad
          <img src={ArrowRight} alt="arrow" />
        </button>
        <div className="messages">
          {status === 'resolving' &&
            <div className="text-center spinner">
              <ClipLoader size={22} color={"#ffffffbb"} />
            </div>
          }
          {message && (
            <div className="error-notice text-center">
              <p>{message}</p>
            </div>
          )}
        </div>
      </form>
      <Link className="hover-underline" to="/">
        <img src={Arrow} alt="arrow" />
        Tillbaka
      </Link>
    </div>
  )
}
