import React from 'react'
import ContactForm from '../../components/forms/ContactForm'
import Layout from '../../components/Layout'
import CookieBar from "../../components/CookieBar";

export default function Contact() {
  return (
    <Layout>
      <ContactForm />
      {/* <CookieBar /> */}
    </Layout>
  )
}
